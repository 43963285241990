import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    View,
    ScreenSpinner,
    AdaptivityProvider,
    AppRoot,
    ConfigProvider,
    SplitLayout,
    SplitCol,
    Panel,
    PanelHeader,
    PanelHeaderBack,
    Group,
    CardGrid,
    ContentCard,
    Div,
    File,
    Button,
    ModalRoot,
    ModalCard,
    FormItem,
    FormLayoutGroup, Select,
    Input,
    Alert
} from '@vkontakte/vkui';
import {  Icon56UserCircleOutline } from '@vkontakte/icons';
import '@vkontakte/vkui/dist/vkui.css';

const MODAL_CARD_UPLOAD = 'upload';
const SERVER_URL = 'https://neuro-media.online'; // Замените на URL вашего сервера
const ADMIN_USER_IDS = [117542103,321024118,473347929,378607107]; // Замените на реальные ID админов
const categories = [
    { id: 1, name: 'Влюблённые', image: 'https://photozone.vktests-app.ru/categories/female/104/3.jpg?d=13022024' },
    { id: 2, name: 'Костюмы', image: 'https://photozone.vktests-app.ru/categories/male/1.jpg' },
    { id: 3, name: 'Спорт', image: 'https://photozone.vktests-app.ru/categories/male/2.jpg' },
    { id: 4, name: 'Профессии', image: 'https://photozone.vktests-app.ru/categories/male/3.jpg' },
    { id: 5, name: 'Женские образы', image: 'https://photozone.vktests-app.ru/categories/female/7/2.jpg?d=13022024' },
];

const categoryImages = {
    1: Array.from({ length: 86 }, (_, i) => i + 1).map(num => ({
        id: num,
        url: `/proxy/images/female/104/${num}.jpeg`
    })),
    2: Array.from({ length: 30 }, (_, i) => i + 1).map(num => ({
        id: num,
        url: `/proxy/images/male/1/${num}.jpeg`
    })),
    3: Array.from({ length: 19 }, (_, i) => i + 1).map(num => ({
        id: num,
        url: `/proxy/images/male/2/${num}.jpeg`
    })),
    4: Array.from({ length: 42 }, (_, i) => i + 1).map(num => ({
        id: num,
        url: `/proxy/images/male/3/${num}.jpeg`
    })),
    5: Array.from({ length: 359 }, (_, i) => i + 1).map(num => ({
        id: num,
        url: `/proxy/images/female/${Math.floor((num - 1) / 100) + 1}/${num}.jpeg`
    }))
};

const Home = ({ id, go, fetchedUser }) => (
    <Panel id={id}>
        <PanelHeader>Выберите категорию</PanelHeader>
        <Group>
            <CardGrid size="m">
                {categories.map((category) => (
                    <ContentCard
                        key={category.id}
                        onClick={() => go('category', { category })}
                        src={category.image}
                        header={<div style={{ textAlign: 'center' }}>{category.name}</div>}
                    />
                ))}
            </CardGrid>
        </Group>
        {fetchedUser && ADMIN_USER_IDS.includes(fetchedUser.id) && (
            <Group>
                <Button size="xl" mode="secondary" onClick={() => go('admin')}>
                    Админ-панель
                </Button>
            </Group>
        )}
    </Panel>
);

const Category = ({ id, go, category, onSelectImage, SERVER_URL, categoryImages = {} }) => {
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
        const fetchImages = async () => {
            try {
                console.log(`Fetching images for category ID: ${category.id}`);
                const response = await axios.get(`${SERVER_URL}/images/${category.id}`, {
                    headers: { 'ngrok-skip-browser-warning': '1' }
                });
                console.log("Response data:", response.data);

                // Объединяем изображения с сервера и из categoryImages
                const serverImages = response.data && response.data.images ? response.data.images.map(img => img.url) : [];
                const defaultImages = categoryImages[category.id] || [];
                const maxImagesInCategory = {
                    1: 86,  // Влюблённые
                    2: 30,  // Костюмы
                    3: 19,  // Спорт
                    4: 42,  // Профессии
                    5: 359  // Женские образы
                };

                const maxImages = maxImagesInCategory[category.id] || 0;
                const filteredDefaultImages = defaultImages
                    .filter((img, index) => index < maxImages)
                    .map(img => img.url);

                const allImages = [...serverImages, ...filteredDefaultImages];
                console.log("All images:", allImages);
                setImages(allImages);

            } catch (error) {
                console.error('Error fetching images:', error);
                // В случае ошибки используем только изображения из categoryImages
                const defaultImages = categoryImages[category.id] || [];
                const maxImagesInCategory = {
                    1: 86,
                    2: 30,
                    3: 19,
                    4: 42,
                    5: 359
                };
                const maxImages = maxImagesInCategory[category.id] || 0;
                const filteredDefaultImages = defaultImages
                    .filter((img, index) => index < maxImages)
                    .map(img => img.url);
                setImages(filteredDefaultImages);
            } finally {
                setIsLoading(false);
            }
        };

        fetchImages();
    }, [category.id, categoryImages]);

    const createImageChunks = (imageArray) => {
        const chunks = [];
        for (let i = 0; i < imageArray.length; i += 4) {
            const chunk = imageArray.slice(i, Math.min(i + 4, imageArray.length));
            chunks.push(chunk);
        }
        return chunks;
    };

    const chunks = createImageChunks(images);
    console.log("Chunks created:", chunks);

    return (
        <Panel id={id}>
            <PanelHeader before={<PanelHeaderBack onClick={() => go('home')} />}>
                Выберите образ
            </PanelHeader>
            <Div>
                {isLoading ? (
                    <ScreenSpinner size='large' />
                ) : images.length > 0 ? (
                    chunks.map((chunk, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                            {chunk.map((imageUrl, idx) => (
                                <div 
                                    key={idx} 
                                    style={{
                                        width: '23%',
                                        margin: '0.25%',
                                    }}
                                >
                                    <img
                                        src={imageUrl}
                                        alt="Category"
                                        onClick={() => onSelectImage({ url: imageUrl }, category)}
                                        style={{
                                            width: '100%',
                                            height: '250px',
                                            borderRadius: '15px',
                                            objectFit: 'cover',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <Div>Нет изображений для отображения</Div>
                )}
            </Div>
        </Panel>
    );
};
  



const Upload = ({ id, go, handleUploadMale, handleUploadFemale, maleImage, femaleImage }) => (
    <Panel id={id}>
        <PanelHeader before={<PanelHeaderBack onClick={() => go('category')} />}>
            Уведомление
        </PanelHeader>
        <Div style={{ textAlign: 'center' }}>
            <b>Загрузите фото с устройства для получения образа.</b>
        </Div>
        <Div>
            <File top="Загрузить мужчину" mode="primary" stretched align='center' onChange={handleUploadMale}>
                {maleImage ? 'Фото мужчины загружено' : 'Загрузите фото мужчины'}
            </File>
        </Div>
        <Div>
            <File top="Загрузить женщину" mode="primary" stretched align='center' onChange={handleUploadFemale}>
                {femaleImage ? 'Фото женщины загружено' : 'Загрузите фото женщины'}
            </File>
        </Div>
    </Panel>
);

const UploadSingle = ({ id, go, handleUpload }) => (
    <Panel id={id}>
        <PanelHeader before={<PanelHeaderBack onClick={() => go('category')} />}>
            Уведомление
        </PanelHeader>
        <Div style={{ textAlign: 'center' }}>
            <b>Загрузите фото с устройства для получения образа.</b>
        </Div>
        <Div>
            <File top="Загрузить фото" mode="primary" stretched align='center' onChange={handleUpload}>
                Загрузите фото
            </File>
        </Div>
    </Panel>
);
const categoriesData = [
    { id: 1, name: 'Влюблённые' },
    { id: 2, name: 'Костюмы' },
    { id: 3, name: 'Спорт' },
    { id: 4, name: 'Профессии' },
    { id: 5, name: 'Женские образы' },
];



const AdminPanel = ({ id, go }) => {
    const [category, setCategory] = useState('');
    const [image, setImage] = useState(null);
    const [message, setMessage] = useState('');
    const [categories, setCategories] = useState([]);
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const [appLaunchUrl, setAppLaunchUrl] = useState('');

    useEffect(() => {
        setCategories(categoriesData); // Используй правильный источник данных для categoriesData
    }, []);

    const handleCategoryChange = (e) => setCategory(e.target.value);

    const handleImageChange = (e) => setImage(e.target.files[0]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!image) {
            setMessage('Пожалуйста, выберите изображение для загрузки');
            return;
        }

        const formData = new FormData();
        formData.append('category', category);
        formData.append('image', image);

        try {
            const response = await axios.post(`${SERVER_URL}/admin/add-image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'ngrok-skip-browser-warning': '1'
                }
            });

            if (response.status === 200) {
                setMessage('Изображение успешно добавлено');
                setUploadedImageUrl(response.data.imageUrl);

                const appId = '52351402'; // Замените на реальный ID вашего приложения
                const encodedImageUrl = encodeURIComponent(response.data.imageUrl);
                const launchUrl = `https://vk.com/app${appId}#category_id=${category}&image_url=${encodedImageUrl}`;
                setAppLaunchUrl(launchUrl);

                // Обновляем список изображений в выбранной категории
                const updatedImagesResponse = await axios.get(`${SERVER_URL}/images/${category}`, {
                    headers: { 'ngrok-skip-browser-warning': '1' }
                });

                const updatedImages = updatedImagesResponse.data;

                // Обновляем состояние с новыми изображениями
                setCategories(updatedImages); // Здесь должно обновляться соответствующее состояние
            } else {
                setMessage('Ошибка при добавлении изображения');
            }
        } catch (error) {
            setMessage('Ошибка при добавлении изображения');
            console.error('Ошибка при добавлении изображения:', error);
        }
    };

    return (
        <Panel id={id}>
            <PanelHeader before={<PanelHeaderBack onClick={() => go('home')} />}>
                Админ-панель
            </PanelHeader>
            <Group>
                <form onSubmit={handleSubmit}>
                    {categories.length > 0 ? (
                        <FormItem top="Категория" htmlFor="select-category">
                            <Select
                                id="select-category"
                                placeholder="Выберите категорию"
                                value={category}
                                onChange={handleCategoryChange}
                                options={categories.map(cat => ({ label: cat.name, value: cat.id }))}
                            />
                        </FormItem>
                    ) : (
                        <Div>Категории не загружены</Div>
                    )}
                    <FormItem top="Изображение" htmlFor="file-image">
                        <File id="file-image" onChange={handleImageChange} accept="image/*" />
                    </FormItem>
                    <FormItem>
                        <Button size="l" stretched type="submit">Добавить образ</Button>
                    </FormItem>
                </form>
            </Group>
            {message && (
                <Group>
                    <Div>{message}</Div>
                </Group>
            )}
            {uploadedImageUrl && (
                <Group>
                    <FormItem top="Ссылка на загруженное изображение">
                        <Input type="text" value={uploadedImageUrl} readOnly />
                    </FormItem>
                </Group>
            )}
            {appLaunchUrl && (
                <Group>
                    <FormItem top="Ссылка для запуска приложения с выбранным изображением">
                        <Input type="text" value={appLaunchUrl} readOnly />
                    </FormItem>
                    <Div>
                        <Button size="l" stretched onClick={() => bridge.send("VKWebAppCopyText", {"text": appLaunchUrl})}>
                            Скопировать ссылку
                        </Button>
                    </Div>
                </Group>
            )}
        </Panel>
    );
};





const App = () => {
    const [activePanel, setActivePanel] = useState('home');
    const [fetchedUser, setUser] = useState(null);
    const [popout, setPopout] = useState(<ScreenSpinner size='large' />);
    const [activeModal, setActiveModal] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [maleImage, setMaleImage] = useState(null);
    const [femaleImage, setFemaleImage] = useState(null);
    const [isCoupleCategory, setIsCoupleCategory] = useState(false);
    const [resultImage, setResultImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const linkGroup = 'https://vk.com/im?sel=-227534214';
    const [saved, setSaveGroupId] = useState('227534214');
    const [countMessages, setCountMessages] = useState(0);
    const [selectedTargetImage, setSelectedTargetImage] = useState(null);
    const [launchParams, setLaunchParams] = useState(null);
    const getLaunchParams = async () => {
        const params = await bridge.send('WebAppGetLaunchParams');
        return params;
    };

    useEffect(() => {
        bridge.subscribe(({ detail: { type, data }}) => {
            if (type === 'VKWebAppUpdateConfig') {
                const schemeAttribute = document.createAttribute('scheme');
                schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
                document.body.setAttributeNode(schemeAttribute);
            }
        });

        async function fetchData() {
            try {
                const user = await bridge.send('VKWebAppGetUserInfo');
                setUser(user);

                const params = await bridge.send('VKWebAppGetLaunchParams');
                setLaunchParams(params);
                console.log('Launch Params:', params);
                setPopout(null);
            } catch (error) {
                console.error("Error fetching data:", error);
                setPopout(null);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (launchParams && launchParams.vk_app_id) {
            const hashString = window.location.hash.substring(1); // Получаем строку после #
            const hash = new URLSearchParams(hashString);
            console.log('Hash:', hashString); // Выводим сам хэш для проверки
    
            const categoryId = hash.get('category_id');
            const imageUrl = hash.get('image_url');
    
            console.log('Category ID:', categoryId);
            console.log('Image URL:', imageUrl);
    
            if (categoryId && imageUrl) {
                const category = categories.find(c => c.id === parseInt(categoryId));
                if (category) {
                    setSelectedCategory(category);
                    setSelectedTargetImage(decodeURIComponent(imageUrl));
                    setActivePanel('imagePreview');
                }
            }
        }
    }, [launchParams]);
    

    const go = (panel, data = {}) => {
        setActivePanel(panel);
        if (data.category) {
            setSelectedCategory(data.category);
        }
    };


    const handleSelectImage = (image, category) => {
        setSelectedImage(image);
        setSelectedTargetImage(image.url); // Сохраняем полный URL выбранного изображения
        setIsCoupleCategory(category.id === 1);
        if (category.id === 1) {
            setActivePanel('upload');
        } else {
            setActiveModal(MODAL_CARD_UPLOAD);
        }
    };

    const handleUploadMale = async (event) => {
        const file = event.target.files[0];
        setMaleImage(file);
        if (femaleImage) {
            await handleImageSwap(file, femaleImage, selectedTargetImage);
        }
    };

    const handleUploadFemale = async (event) => {
        const file = event.target.files[0];
        setFemaleImage(file);
        if (maleImage) {
            await handleImageSwap(maleImage, file, selectedTargetImage);
        }
    };

    const handleUploadSingle = async (event) => {
        const file = event.target.files[0];
        await handleImageSwap(file, null, selectedTargetImage);
      };

    const handleImageSwap = async (sourceImage1, sourceImage2, targetImageUrl) => {
        try {
            setIsLoading(true);
            const formData = new FormData();
      
            // Добавляем первое изображение
            if (sourceImage1 instanceof Blob || sourceImage1 instanceof File) {
              formData.append('source', sourceImage1, 'source.jpg');
            } else {
              throw new Error('Invalid source image');
            }
      
            // Если категория "пары" активна, добавляем второе изображение
            if (isCoupleCategory && (sourceImage2 instanceof Blob || sourceImage2 instanceof File)) {
              formData.append('female_source', sourceImage2, 'female_source.jpg');
            }
      
            // Добавляем URL целевого изображения
            formData.append('target', targetImageUrl);
      
            // Определяем конечную точку API для обмена лиц
            const endpoint = isCoupleCategory ? '/swap_couple' : '/swap_face';
            const response = await axios.post(`${SERVER_URL}${endpoint}`, formData, {
              responseType: 'blob',
              headers: {
                'Content-Type': 'multipart/form-data',
                'ngrok-skip-browser-warning': '1'
              },
              timeout: 60000 // Изменено на миллисекунды
            });
    
            // Получаем результат и отображаем
            const resultBlob = new Blob([response.data], { type: 'image/jpeg' });
            const imageUrl = URL.createObjectURL(resultBlob);
            setResultImage(imageUrl);
            setActivePanel('result');
            setActiveModal(null);
    
        } catch (error) {
            console.error('Error in face swap:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            }
            alert('Произошла ошибка при обработке изображений. Пожалуйста, попробуйте еще раз.');
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleUploadAvatar = async () => {
        try {
          const userInfo = await bridge.send('VKWebAppGetUserInfo');
          if (!userInfo.photo_200) {
            throw new Error('Не удалось получить фотографию пользователя');
          }
    
          const avatarBlob = await fetch(userInfo.photo_200).then(r => r.blob());
          await handleImageSwap(avatarBlob, null, selectedTargetImage);
        } catch (error) {
          console.error('Error uploading avatar:', error);
          alert('Произошла ошибка при загрузке аватара. Пожалуйста, попробуйте еще раз.');
        }
      };

    const modal = (
        <ModalRoot activeModal={activeModal} onClose={() => setActiveModal(null)}>
            <ModalCard
                id={MODAL_CARD_UPLOAD}
                onClose={() => setActiveModal(null)}
                icon={<Icon56UserCircleOutline />}
                header="Загрузка фото для результата"
                subheader="Выберите вариант загрузки фото для получения результата"
                actions={
                    <Div>
                        <Button size="l" mode="primary" onClick={handleUploadAvatar} stretched style={{ marginBottom: 8 }}>
                            Загрузить с аватарки
                        </Button>
                        <File top="Загрузить с устройства" mode="primary" onChange={handleUploadSingle} stretched align='center'>
                            Загрузить с устройства
                        </File>
                    </Div>
                }
            />
        </ModalRoot>
    );

    return (
        <ConfigProvider appearance="light">
            <AdaptivityProvider>
                <AppRoot>
                    <SplitLayout modal={modal} popout={isLoading ? <ScreenSpinner size='large' /> : null}>
                        <SplitCol>
                            <View activePanel={activePanel} popout={popout}>
                            {fetchedUser && ADMIN_USER_IDS.includes(fetchedUser.id) && (
        <AdminPanel id='admin' go={go} categories={categories} />
    )}
                                <Home id='home' go={go} fetchedUser={fetchedUser} />

                                <Category id='category' go={go} category={selectedCategory} onSelectImage={handleSelectImage} SERVER_URL={SERVER_URL}/>

                                {isCoupleCategory ? (
                                    <Upload
                                        id='upload'
                                        go={go}
                                        handleUploadMale={handleUploadMale}
                                        handleUploadFemale={handleUploadFemale}
                                        maleImage={maleImage}
                                        femaleImage={femaleImage}
                                    />
                                ) : (
                                    <UploadSingle
                                        id='upload'
                                        go={go}
                                        handleUpload={handleUploadSingle}
                                    />
                                )}
                                <Result
                                    id='result'
                                    resultImage={resultImage}
                                    go={go}
                                    onSelectAnotherLook={() => go('category')}
                                    linkGroup={linkGroup}
                                    userId={fetchedUser?.id}
                                />
                                <ImagePreview
                                    id='imagePreview'
                                    imageUrl={selectedTargetImage}
                                    handleUploadAvatar={handleUploadAvatar}
                                    handleUploadSingle={handleUploadSingle}
                                    go={go}
                                />

                            </View>
                        </SplitCol>
                    </SplitLayout>
                </AppRoot>
            </AdaptivityProvider>
        </ConfigProvider>
    );
};
const ImagePreview = ({ id, imageUrl, handleUploadAvatar, handleUploadSingle, go }) => (
    <Panel id={id}>
        <PanelHeader
            before={<PanelHeaderBack onClick={() => go('home')} />} // Кнопка назад, которая переключает на панель 'home'
        >
            Предпросмотр изображения
        </PanelHeader>
        <Div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={imageUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '50vh', marginBottom: '20px' }} />
            <Button size="l" mode="primary" onClick={handleUploadAvatar} stretched style={{ marginBottom: '8px' }}>
                Загрузить с аватарки
            </Button>
            <File top="Загрузить с устройства" mode="primary" onChange={handleUploadSingle} stretched align="center">
                Загрузить с устройства
            </File>
        </Div>
    </Panel>
);



const Result = ({ id, resultImage, go, onSelectAnotherLook, linkGroup, userId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [popout, setPopout] = useState(null);

    const showAlert = (title, message, onClose) => {
        setPopout(
            <Alert
                actions={[{
                    title: 'OK',
                    autoclose: true,
                    mode: 'default'
                }]}
                onClose={onClose}
                header={title}
                text={message}
            />
        );
    };

    const handleSelectThisLook = async () => {
        try {
            setIsLoading(true);
            const userInfo = await bridge.send('VKWebAppGetUserInfo');
            const userId = userInfo.id;
    
            const authResponse = await bridge.send('VKWebAppGetAuthToken', {
                app_id: 52351402,
                scope: 'photos,wall,pages'
            });
    
            if (!authResponse.access_token) {
                throw new Error('Failed to get access token');
            }
    
            const accessToken = authResponse.access_token;
    
            // Save the image on the server
            const saveImageFormData = new FormData();
            saveImageFormData.append('image', await fetch(resultImage).then(r => r.blob()), 'image.jpg');
            saveImageFormData.append('user_id', userId);
    
            const saveImageResponse = await fetch(`${SERVER_URL}/save_result`, {
                method: 'POST',
                body: saveImageFormData
            });
    
            if (!saveImageResponse.ok) {
                throw new Error('Failed to save image on server');
            }
    
            const saveImageResult = await saveImageResponse.json();
            const savedImageUrl = `${SERVER_URL}${saveImageResult.image_url}`;
    
            const uploadServerResponse = await bridge.send('VKWebAppCallAPIMethod', {
                method: 'photos.getWallUploadServer',
                params: {
                    access_token: accessToken,
                    v: '5.199'
                }
            });
            const uploadUrl = uploadServerResponse.response.upload_url;
    
            const formData = new FormData();
            formData.append('upload_url', uploadUrl);
            formData.append('photo', await fetch(savedImageUrl).then(r => r.blob()), 'image.jpg');
            
    
            console.log('Upload to VK request:', {
                url: `${SERVER_URL}/upload_to_vk`,
                method: 'POST',
                body: formData
            });
    
            const uploadResponse = await fetch(`${SERVER_URL}/upload_to_vk`, {
                method: 'POST',
                body: formData
            });
    
            if (!uploadResponse.ok) {
                throw new Error('Failed to upload image');
            }
    
            const uploadResult = await uploadResponse.json();
    
            console.log('Save Wall Photo request:', {
                method: 'photos.saveWallPhoto',
                params: {
                    access_token: accessToken,
                    user_id: userId,
                    photo: uploadResult.photo,
                    server: uploadResult.server,
                    hash: uploadResult.hash,
                    v: '5.199'
                }
            });
    
            const savePhotoResponse = await bridge.send('VKWebAppCallAPIMethod', {
                method: 'photos.saveWallPhoto',
                params: {
                    access_token: accessToken,
                    user_id: userId,
                    photo: uploadResult.photo,
                    server: uploadResult.server,
                    hash: uploadResult.hash,
                    v: '5.199'
                }
            });
    
            const savedPhoto = savePhotoResponse.response;
            const notifyBotToSendMessage = async (userId, photoOwnerId, photoId) => {
                try {
                  const response = await fetch(`${SERVER_URL}/notify_bot`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      user_id: userId,
                      photo_owner_id: photoOwnerId,
                      photo_id: photoId
                    })
                  });
              
                  if (!response.ok) {
                    throw new Error('Ошибка при отправке уведомления боту');
                  }
              
                  const result = await response.json();
                  console.log('Уведомление боту отправлено успешно:', result);
                } catch (error) {
                  console.error('Ошибка при отправке уведомления боту:', error);
                  throw error;
                }
              };
            console.log('Show Wall Post Box request:', {
                method: 'VKWebAppShowWallPostBox',
                params: {
                    message: 'Тут можно создать прекрасные образы --> https://vk.com/app52351402',
                    attachments: `photo${savedPhoto.owner_id}_${savedPhoto.id},https://vk.com/app52351402`
                }
            });
            await notifyBotToSendMessage(userId, savedPhoto[0].owner_id, savedPhoto[0].id);
              const postResponse = await bridge.send('VKWebAppShowWallPostBox', {
                message: 'Тут можно создать прекрасные образы --> https://vk.com/app52351402',
                attachments: `photo${savedPhoto[0].owner_id}_${savedPhoto[0].id},https://vk.com/app52351402`
            });
    
            if (postResponse.post_id) {
                showAlert('Успех', 'Запись успешно опубликована на вашей стене!', async () => {
                  try {
                    
                  } catch (error) {
                    console.error('Ошибка при уведомлении бота:', error);
                  }
                });
              } else {
                throw new Error('Ошибка при публикации записи');
              }
        } catch (error) {
            console.error('Error in handleSelectThisLook:', error);
            showAlert('Ошибка', 'Произошла ошибка при публикации записи. Пожалуйста, попробуйте ещё раз.');
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleSetAsAvatar = async () => {
        try {
            setIsLoading(true);
            const getImageDimensions = (url) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = () => resolve({ width: img.width, height: img.height });
                    img.onerror = reject;
                    img.src = url;
                });
            };
    
            const dimensions = await getImageDimensions(resultImage);
            console.log(`Image dimensions: ${dimensions.width}x${dimensions.height}`);
    
            const authResponse = await bridge.send('VKWebAppGetAuthToken', {
                app_id: 52351402,
                scope: 'photos,wall,pages'
            });
    
            if (!authResponse.access_token) {
                throw new Error('Failed to get access token');
            }
    
            const accessToken = authResponse.access_token;
    
            // Save the image on the server
            const saveImageFormData = new FormData();
            saveImageFormData.append('image', await fetch(resultImage).then(r => r.blob()), 'avatar.jpg');
            saveImageFormData.append('user_id', userId);
    
            const saveImageResponse = await fetch(`${SERVER_URL}/save_result`, {
                method: 'POST',
                body: saveImageFormData
            });
    
            if (!saveImageResponse.ok) {
                throw new Error('Failed to save image on server');
            }
    
            const saveImageResult = await saveImageResponse.json();
            const savedImageUrl = `${SERVER_URL}${saveImageResult.image_url}`;
    
            // Get Owner Photo Upload Server
            const uploadServerResponse = await bridge.send('VKWebAppCallAPIMethod', {
                method: 'photos.getOwnerPhotoUploadServer',
                params: {
                    access_token: accessToken,
                    owner_id: userId,
                    v: '5.199'
                }
            });
            const uploadUrl = uploadServerResponse.response.upload_url;
    
            const formData = new FormData();
            formData.append('upload_url', uploadUrl);
            formData.append('photo', await fetch(savedImageUrl).then(r => r.blob()), 'avatar.jpg');
            
    
            // Логирование HTTP запроса
            console.log('Upload to VK request:', {
                url: `${SERVER_URL}/upload_to_vk`,
                method: 'POST',
                body: formData
            });
    
            const uploadResponse = await fetch(`${SERVER_URL}/upload_to_vk`, {
                method: 'POST',
                body: formData
            });
    
            if (!uploadResponse.ok) {
                throw new Error('Failed to upload image');
            }
    
            const uploadResult = await uploadResponse.json();
    
            // Логирование HTTP запроса
            console.log('Save Owner Photo request:', {
                method: 'photos.saveOwnerPhoto',
                params: {
                    access_token: accessToken,
                    server: uploadResult.server,
                    photo: uploadResult.photo,
                    hash: uploadResult.hash,
                    v: '5.199'
                }
            });
    
            const savePhotoResponse = await bridge.send('VKWebAppCallAPIMethod', {
                method: 'photos.saveOwnerPhoto',
                params: {
                    access_token: accessToken,
                    server: uploadResult.server,
                    photo: uploadResult.photo,
                    hash: uploadResult.hash,
                    v: '5.199'
                }
            });
    
            if (savePhotoResponse.response) {
                console.log(savePhotoResponse.response);
                showAlert('Успех', 'Аватар успешно обновлен!', () => {
                });
            } else {
                throw new Error('Failed to save avatar');
            }
        } catch (error) {
            console.error('Error in handleSetAsAvatar:', error);
            showAlert('Ошибка', 'Произошла ошибка при обновлении аватара. Пожалуйста, попробуйте ещё раз.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Panel id={id}>
            <PanelHeader before={<PanelHeaderBack onClick={() => go('home')} />}>
                Результат
            </PanelHeader>
            <Div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '80vh' }}>
                {resultImage && (
                    <>
                        <img src={resultImage} alt="Result" style={{ maxWidth: '100%', maxHeight: '70%', marginBottom: '20px' }} />
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <a href='https://vk.com/im?sel=-227534214' target='_blank'><Button size="l" mode="primary" onClick={handleSelectThisLook} disabled={isLoading} style={{ marginRight: '10px', marginBottom: '10px' }}>
                                {isLoading ? 'Публикация...' : 'Выбрать этот образ'}
                            </Button></a>
                            <a href='https://vk.com/im?sel=-227534214' target='_blank'><Button size="l" mode="secondary" onClick={handleSetAsAvatar} disabled={isLoading} style={{ marginRight: '10px', marginBottom: '10px' }}>
                                Установить как аватар
                            </Button></a>
                            <Button size="l" mode="secondary" onClick={onSelectAnotherLook} disabled={isLoading}>
                                Выбрать другой образ
                            </Button>
                        </div>
                    </>
                )}
            </Div>
            {popout}
        </Panel>
    );
};

export default App;